.stories-v1_0 {
  @import "../shared/_defaults";

  .posts {
    @include simple-center-align;
    width: 56.25vh;
    height: 100vh;

    @media screen and (max-aspect-ratio: 9/16) {
      width: 100vw;
      height: calc(100vw / .5625);
    }
  }

  .post {
    @include simple-center-align;
    width: 85%;
    height: 85%;
    font-size: 3vmin;
    color: $white;
    opacity: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .media-module {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  .blurred-media-module {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .media,
  .overlay {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
  }

  .text-module {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: .5em;
    overflow: hidden;
  }

  .meta-author {
    position: relative;
    font-size: .75em;
    padding-right: 2.5em;
    text-shadow: 0 1px 2px rgba($black, .75), 0 0 3px rgba($black, .5);
  }

  .author {
    @include ellipsis;
    display: inline-block;
    max-width: 100%;
    line-height: 1.5;
  }

  .origin-flag {
    position: absolute;
    top: .5em;
    right: .5em;
    width: 1.5em;
    height: 1.5em;
    border-radius: .2em;
    color: $white;
  }

  .instagram-stories {
    .origin-flag {
      @include instagramGradient;

      &:before {
        font-size: .8em;
        @include instagram-logo-icon;
      }
    }
  }
}
